import React, { FC, useCallback } from 'react';
import './AppGallery.scss';

export const AppGallery: FC = () => {

  const handleStoreClick = useCallback(() => (window as any)?.ym(86361007, 'reachGoal', 'klik_na_magazin'), []);

  return (
    <a onClick={handleStoreClick} href="https://redirect.appmetrica.yandex.com/serve/963872057915958535" target="_blank" rel="noreferrer">
      <div className="app-gallery"></div>
    </a>
  )
};
