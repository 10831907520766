import React, { FC, useCallback } from 'react';

export const AppStoreIcon: FC = () => {

  const handleStoreClick = useCallback(() => (window as any)?.ym(86361007, 'reachGoal', 'klik_na_magazin'), []);

  return (
    <a onClick={handleStoreClick} href="https://redirect.appmetrica.yandex.com/serve/963872057915958535" target="_blank" rel="noreferrer">
      <svg width="189" height="59" viewBox="0 0 189 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M173.949 0.00019175H15.0593C14.4801 0.00019175 13.9079 0.00019175 13.3302 0.00314175C12.8467 0.00609175 12.367 0.0146615 11.8788 0.0218743C10.8183 0.0335294 9.7602 0.120664 8.71383 0.282522C7.66892 0.447902 6.65674 0.759693 5.71154 1.20735C4.76748 1.65879 3.90487 2.24539 3.155 2.94587C2.4012 3.64438 1.77284 4.45177 1.2941 5.33698C0.814034 6.22043 0.481156 7.16713 0.306961 8.14438C0.131109 9.12039 0.0364821 10.1077 0.0239282 11.0973C0.0092712 11.5496 0.00772337 12.0033 0 12.4556V46.5491C0.00772337 47.0071 0.0092712 47.4508 0.0239282 47.9089C0.0364859 48.8986 0.131113 49.8858 0.306961 50.8617C0.480675 51.8396 0.813573 52.7868 1.2941 53.6706C1.77262 54.5529 2.40107 55.357 3.155 56.0517C3.90203 56.7553 4.7652 57.3423 5.71154 57.7903C6.65674 58.2391 7.6688 58.5528 8.71383 58.7207C9.76038 58.8813 10.8183 58.9684 11.8788 58.9815C12.367 58.9915 12.8467 58.9973 13.3302 58.9973C13.9079 59.0002 14.4801 59.0002 15.0593 59.0002H173.949C174.517 59.0002 175.094 59.0002 175.661 58.9973C176.143 58.9973 176.636 58.9915 177.117 58.9815C178.176 58.9691 179.232 58.8819 180.276 58.7207C181.325 58.5516 182.34 58.238 183.29 57.7903C184.236 57.342 185.098 56.755 185.844 56.0517C186.596 55.3543 187.226 54.5508 187.711 53.6706C188.187 52.7862 188.517 51.839 188.688 50.8617C188.865 49.8856 188.962 48.8986 188.982 47.9089C188.988 47.4508 188.988 47.0071 188.988 46.5491C189 46.0133 189 45.4804 189 44.9358V14.066C189 13.5258 189 12.99 188.988 12.4556C188.988 12.0033 188.988 11.5496 188.982 11.0973C188.962 10.1075 188.865 9.12047 188.688 8.14432C188.517 7.16763 188.187 6.22101 187.711 5.33692C186.736 3.56261 185.19 2.11833 183.29 1.20721C182.34 0.760654 181.325 0.448946 180.276 0.282389C179.232 0.119818 178.176 0.0326532 177.117 0.0216678C176.636 0.0144698 176.143 0.00582625 175.661 0.00295C175.094 0 174.517 0 173.949 0V0.00019175Z" fill="#A6A6A6" />
        <path d="M13.338 57.7093C12.8568 57.7093 12.3872 57.7035 11.9097 57.6935C10.9207 57.6814 9.93393 57.601 8.95759 57.4529C8.04719 57.3065 7.16527 57.0341 6.3409 56.6448C5.52408 56.2586 4.77909 55.7523 4.13444 55.1453C3.48047 54.5454 2.93601 53.8496 2.52263 53.0854C2.1047 52.3164 1.81546 51.492 1.665 50.6411C1.50252 49.7267 1.41461 48.8022 1.40203 47.8754C1.39202 47.5644 1.37891 46.5286 1.37891 46.5286V12.4553C1.37891 12.4553 1.39287 11.4355 1.40211 11.1359C1.41415 10.2106 1.50155 9.28746 1.66355 8.37455C1.81429 7.5213 2.10375 6.69456 2.5219 5.92296C2.93376 5.15937 3.4752 4.46305 4.12524 3.86098C4.77454 3.25319 5.52192 2.74429 6.34012 2.35284C7.1626 1.96474 8.04286 1.69428 8.95141 1.55053C9.93096 1.40091 10.9211 1.32002 11.9136 1.30854L13.3388 1.29053H175.654L177.096 1.30926C178.079 1.32017 179.06 1.40034 180.031 1.54908C180.949 1.69464 181.838 1.96699 182.67 2.35716C184.309 3.14606 185.643 4.39417 186.484 5.92656C186.896 6.69283 187.181 7.51282 187.33 8.35871C187.494 9.27911 187.585 10.2095 187.604 11.1423C187.609 11.5601 187.609 12.0088 187.609 12.4553C187.621 13.0084 187.621 13.5349 187.621 14.0657V44.9355C187.621 45.4713 187.621 45.9942 187.609 46.5214C187.609 47.0011 187.609 47.4405 187.603 47.8927C187.584 48.8089 187.494 49.7227 187.333 50.6266C187.185 51.4837 186.898 52.3145 186.48 53.0899C186.063 53.8457 185.522 54.5355 184.876 55.1338C184.23 55.7441 183.484 56.2534 182.665 56.642C181.836 57.0343 180.948 57.3077 180.031 57.4529C179.055 57.6018 178.068 57.6822 177.079 57.6935C176.616 57.7035 176.132 57.7093 175.661 57.7093L173.949 57.7122L13.338 57.7093Z" fill="black" />
        <path d="M39.1202 29.9432C39.1372 28.7121 39.4874 27.5049 40.1381 26.4342C40.7889 25.3634 41.7191 24.4637 42.8422 23.8189C42.1287 22.8673 41.1875 22.0841 40.0932 21.5317C38.999 20.9792 37.7818 20.6726 36.5385 20.6363C33.8863 20.3763 31.315 22.1184 29.9635 22.1184C28.5858 22.1184 26.5049 20.6621 24.2642 20.7052C22.8148 20.7489 21.4022 21.1425 20.1642 21.8477C18.9261 22.5528 17.9047 23.5454 17.1996 24.7288C14.145 29.6677 16.4234 36.9263 19.3495 40.9185C20.8134 42.8734 22.5244 45.0571 24.7631 44.9796C26.9539 44.8948 27.7721 43.675 30.4165 43.675C33.0364 43.675 33.804 44.9796 36.0882 44.9304C38.439 44.8948 39.9201 42.9668 41.3327 40.9934C42.3846 39.6005 43.194 38.061 43.7309 36.432C42.3652 35.8925 41.1997 34.9895 40.3798 33.8357C39.5599 32.6818 39.1218 31.328 39.1202 29.9432Z" fill="white" />
        <path d="M34.8054 18.011C36.0872 16.574 36.7186 14.727 36.5657 12.8623C34.6075 13.0544 32.7987 13.9284 31.4996 15.3102C30.8644 15.9853 30.378 16.7706 30.068 17.6214C29.7581 18.4721 29.6307 19.3715 29.6932 20.2683C30.6727 20.2777 31.6416 20.0794 32.5272 19.6884C33.4127 19.2974 34.1917 18.7239 34.8054 18.011Z" fill="white" />
        <path d="M66.8129 40.0313H59.3369L57.5416 44.9821H54.375L61.4562 26.6655H64.7461L71.8273 44.9821H68.6067L66.8129 40.0313ZM60.1112 37.7468H66.0372L63.1159 29.7121H63.0341L60.1112 37.7468Z" fill="white" />
        <path d="M87.1203 38.3054C87.1203 42.4553 84.7419 45.1215 81.1527 45.1215C80.2435 45.1659 79.3393 44.9703 78.5436 44.5571C77.7479 44.1439 77.0928 43.5298 76.6535 42.7851H76.5856V49.3996H73.6504V31.6275H76.4915V33.8487H76.5455C77.0051 33.1075 77.6712 32.4966 78.4729 32.0809C79.2747 31.6652 80.1821 31.4602 81.0987 31.4878C84.7279 31.4878 87.1203 34.167 87.1203 38.3054ZM84.1033 38.3054C84.1033 35.6017 82.6072 33.8242 80.3244 33.8242C78.0818 33.8242 76.5733 35.6391 76.5733 38.3054C76.5733 40.9961 78.0818 42.7981 80.3244 42.7981C82.6072 42.7981 84.1033 41.0336 84.1033 38.3054Z" fill="white" />
        <path d="M102.859 38.3054C102.859 42.4552 100.48 45.1215 96.891 45.1215C95.9818 45.1659 95.0776 44.9703 94.2819 44.5571C93.4861 44.1439 92.8311 43.5298 92.3918 42.7851H92.3239V49.3995H89.3887V31.6275H92.2298V33.8487H92.2838C92.7433 33.1075 93.4094 32.4966 94.2111 32.0809C95.0129 31.6652 95.9204 31.4602 96.8369 31.4878C100.466 31.4878 102.859 34.167 102.859 38.3054ZM99.8416 38.3054C99.8416 35.6017 98.3454 33.8242 96.0627 33.8242C93.82 33.8242 92.3116 35.6391 92.3116 38.3054C92.3116 40.9961 93.82 42.7981 96.0627 42.7981C98.3455 42.7981 99.8416 41.0335 99.8416 38.3054H99.8416Z" fill="white" />
        <path d="M113.261 39.8784C113.479 41.6948 115.368 42.8874 117.95 42.8874C120.424 42.8874 122.204 41.6947 122.204 40.0569C122.204 38.6352 121.131 37.7839 118.589 37.2006L116.047 36.6287C112.446 35.8163 110.774 34.2433 110.774 31.6909C110.774 28.5306 113.723 26.3599 117.91 26.3599C122.055 26.3599 124.896 28.5306 124.991 31.6909H122.028C121.851 29.863 120.233 28.7596 117.869 28.7596C115.504 28.7596 113.886 29.876 113.886 31.5008C113.886 32.7957 114.92 33.5577 117.448 34.141L119.608 34.6365C123.633 35.5253 125.305 37.0349 125.305 39.714C125.305 43.1408 122.382 45.2871 117.733 45.2871C113.383 45.2871 110.447 43.1912 110.257 39.8782L113.261 39.8784Z" fill="white" />
        <path d="M131.639 28.4673V31.6276H134.358V33.7983H131.639V41.1604C131.639 42.3041 132.183 42.837 133.378 42.837C133.701 42.8318 134.024 42.8106 134.344 42.7736V44.9314C133.807 45.0251 133.26 45.0676 132.714 45.0581C129.819 45.0581 128.69 44.0426 128.69 41.4527V33.7983H126.61V31.6276H128.689V28.4673H131.639Z" fill="white" />
        <path d="M135.933 38.3054C135.933 34.1037 138.582 31.4634 142.715 31.4634C146.861 31.4634 149.498 34.1036 149.498 38.3054C149.498 42.5187 146.874 45.1475 142.715 45.1475C138.556 45.1475 135.933 42.5187 135.933 38.3054ZM146.507 38.3054C146.507 35.4231 145.093 33.722 142.715 33.722C140.336 33.722 138.923 35.4361 138.923 38.3054C138.923 41.1992 140.336 42.8874 142.715 42.8874C145.093 42.8874 146.507 41.1992 146.507 38.3054H146.507Z" fill="white" />
        <path d="M151.918 31.6274H154.717V33.9004H154.785C154.975 33.1905 155.42 32.5631 156.046 32.1237C156.673 31.6844 157.442 31.4598 158.225 31.4877C158.563 31.4866 158.9 31.5209 159.231 31.59V34.1539C158.804 34.0321 158.358 33.9761 157.912 33.9883C157.485 33.9721 157.06 34.0423 156.666 34.1941C156.271 34.3458 155.916 34.5755 155.626 34.8674C155.335 35.1593 155.116 35.5065 154.982 35.8851C154.849 36.2637 154.805 36.6647 154.853 37.0607V44.9816H151.918L151.918 31.6274Z" fill="white" />
        <path d="M172.763 41.0595C172.369 43.4838 169.841 45.1475 166.606 45.1475C162.446 45.1475 159.864 42.5446 159.864 38.3688C159.864 34.18 162.46 31.4634 166.483 31.4634C170.439 31.4634 172.927 34.0014 172.927 38.0505V38.9896H162.827V39.1553C162.781 39.6467 162.846 40.1418 163.021 40.6077C163.195 41.0736 163.473 41.4995 163.837 41.8573C164.2 42.215 164.642 42.4964 165.131 42.6827C165.62 42.869 166.146 42.956 166.674 42.9378C167.368 42.9985 168.064 42.8485 168.659 42.5101C169.254 42.1716 169.716 41.6629 169.976 41.0595L172.763 41.0595ZM162.841 37.0738H169.99C170.016 36.6319 169.945 36.1897 169.779 35.7749C169.614 35.3602 169.359 34.9818 169.029 34.6635C168.7 34.3453 168.304 34.094 167.866 33.9255C167.427 33.7571 166.957 33.675 166.483 33.6845C166.005 33.6819 165.531 33.7676 165.089 33.9369C164.646 34.1061 164.244 34.3555 163.906 34.6706C163.567 34.9857 163.299 35.3603 163.116 35.7728C162.933 36.1853 162.84 36.6275 162.841 37.0739V37.0738Z" fill="white" />
        <path d="M58.0249 16.5987H59.2851C59.502 16.6239 59.7222 16.6069 59.9317 16.5487C60.1412 16.4905 60.3356 16.3924 60.5025 16.2606C60.6695 16.1288 60.8053 15.9661 60.9016 15.7828C60.9979 15.5996 61.0525 15.3996 61.0619 15.1957C61.0619 14.384 60.4079 13.8597 59.2989 13.8597C59.0767 13.8307 58.8505 13.8436 58.6336 13.8976C58.4168 13.9516 58.2138 14.0456 58.0365 14.1741C57.8592 14.3026 57.7113 14.4629 57.6015 14.6457C57.4917 14.8285 57.4222 15.0299 57.3972 15.2382H56.0136C56.0374 14.8601 56.1443 14.4907 56.3276 14.1529C56.5109 13.8151 56.7667 13.5162 57.0791 13.2747C57.3915 13.0333 57.7538 12.8544 58.1433 12.7494C58.5329 12.6443 58.9415 12.6153 59.3436 12.6641C61.1991 12.6641 62.5056 13.6342 62.5056 15.0307C62.51 15.4986 62.3424 15.9537 62.0308 16.32C61.7192 16.6864 61.2825 16.9418 60.7935 17.0437V17.1475C61.3732 17.1715 61.9191 17.4088 62.3119 17.8076C62.7047 18.2064 62.9124 18.7343 62.8897 19.2757C62.8468 19.6649 62.7211 20.0423 62.52 20.3856C62.319 20.7288 62.0465 21.0312 61.7187 21.2749C61.3909 21.5186 61.0143 21.6987 60.611 21.8047C60.2076 21.9107 59.7857 21.9405 59.3699 21.8922C58.9532 21.9453 58.5294 21.9194 58.1236 21.8163C57.7179 21.7131 57.3385 21.5346 57.0082 21.2916C56.6779 21.0486 56.4033 20.7459 56.2009 20.4017C55.9985 20.0574 55.8724 19.6787 55.8301 19.2879H57.2352C57.32 20.1299 58.1483 20.673 59.3699 20.673C60.5915 20.673 61.3889 20.063 61.3889 19.203C61.3889 18.3308 60.6377 17.7935 59.3252 17.7935H58.0249V16.5987Z" fill="white" />
        <path d="M64.4863 19.8009C64.4863 18.6053 65.4411 17.9161 67.1315 17.8181L69.058 17.7144V17.1411C69.058 16.4396 68.5629 16.0435 67.602 16.0435C66.8185 16.0435 66.2771 16.3122 66.1197 16.7817H64.7609C64.9043 15.6409 66.055 14.9092 67.6683 14.9092C69.4513 14.9092 70.457 15.7381 70.457 17.1411V21.6792H69.1043V20.7458H68.9932C68.7678 21.0806 68.4513 21.3536 68.076 21.5368C67.7007 21.72 67.28 21.8069 66.857 21.7886C66.5584 21.8176 66.2567 21.7879 65.9712 21.7013C65.6857 21.6147 65.4228 21.4732 65.1994 21.2859C64.9761 21.0987 64.7972 20.8698 64.6744 20.614C64.5516 20.3582 64.4875 20.0812 64.4863 19.8009ZM69.058 19.2333V18.6781L67.3212 18.7818C66.3418 18.843 65.8976 19.1541 65.8976 19.7396C65.8976 20.3374 66.4528 20.6853 67.2164 20.6853C67.4401 20.7064 67.6661 20.6853 67.8809 20.6233C68.0957 20.5612 68.295 20.4594 68.4668 20.3239C68.6386 20.1885 68.7795 20.0221 68.8811 19.8347C68.9826 19.6474 69.0428 19.4429 69.058 19.2333Z" fill="white" />
        <path d="M74.1819 16.1405V21.6789H72.7783V15.0371H77.4611V16.1404L74.1819 16.1405Z" fill="white" />
        <path d="M85.9668 18.3551C85.9668 20.4654 84.8239 21.7884 83.0285 21.7884C82.5878 21.8165 82.1476 21.7265 81.7599 21.5288C81.3721 21.3312 81.0529 21.0342 80.8398 20.6728H80.7288V23.8871H79.3252V15.0371H80.6763V16.1348H80.7812C80.9981 15.7608 81.3215 15.45 81.7156 15.2369C82.1098 15.0238 82.5593 14.9165 83.0146 14.927C84.8238 14.9269 85.9668 16.2442 85.9668 18.3551ZM84.5231 18.3551C84.5231 16.9522 83.812 16.1045 82.6229 16.1045C81.4415 16.1045 80.7026 16.9702 80.7026 18.3609C80.7026 19.7452 81.4414 20.6116 82.6167 20.6116C83.812 20.6116 84.5231 19.7639 84.5231 18.3551Z" fill="white" />
        <path d="M87.8363 24.0578V22.9292C87.9882 22.9473 88.1412 22.9554 88.2943 22.9537C88.5934 22.9744 88.8901 22.8912 89.1275 22.7202C89.3649 22.5491 89.5263 22.3022 89.5807 22.0268L89.6856 21.7034L87.1113 15.0371H88.6398L90.4229 20.3127H90.534L92.3108 15.0371H93.7992L91.1479 21.9533C90.5402 23.5695 89.8677 24.0881 88.4178 24.0881C88.3006 24.0881 87.9674 24.0758 87.8363 24.0578Z" fill="white" />
        <path d="M94.8926 19.7942H96.29C96.4273 20.3798 97.015 20.6851 97.837 20.6851C98.7717 20.6851 99.3455 20.3005 99.3455 19.7027C99.3455 19.0625 98.8303 18.7571 97.837 18.7571H96.7728V17.7387H97.8185C98.6668 17.7387 99.0987 17.4341 99.0987 16.8788C99.0987 16.3912 98.6807 16.0189 97.8185 16.0189C97.0334 16.0189 96.4597 16.33 96.3686 16.8543H95.016C95.1171 16.2518 95.4674 15.7105 95.9915 15.3467C96.5156 14.9829 97.1716 14.8257 97.8185 14.909C99.509 14.909 100.476 15.6227 100.476 16.7448C100.484 17.0573 100.377 17.3628 100.172 17.6103C99.968 17.8579 99.6788 18.0323 99.3532 18.1046V18.2083C99.7409 18.255 100.097 18.433 100.353 18.7085C100.61 18.9839 100.749 19.3378 100.744 19.7028C100.744 20.9654 99.5938 21.8007 97.837 21.8007C96.2838 21.8007 95.0222 21.1359 94.8926 19.7942Z" fill="white" />
        <path d="M102.767 21.6789V15.0371H104.158V19.6054H104.269L107.546 15.0371H108.938V21.6789H107.546V17.0861H107.442L104.158 21.679L102.767 21.6789Z" fill="white" />
        <path d="M116.601 16.1404H114.362V21.6789H112.956V16.1404H110.717V15.0371H116.601V16.1404Z" fill="white" />
        <path d="M124.163 19.886C123.971 20.493 123.558 21.0179 122.994 21.3691C122.431 21.7204 121.753 21.8755 121.079 21.8075C120.611 21.8191 120.145 21.7353 119.715 21.5619C119.284 21.3885 118.899 21.1298 118.587 20.8036C118.274 20.4774 118.041 20.0916 117.905 19.673C117.768 19.2544 117.73 18.8129 117.794 18.3793C117.732 17.9443 117.77 17.5019 117.907 17.082C118.043 16.6621 118.275 16.2746 118.586 15.9455C118.897 15.6165 119.28 15.3537 119.709 15.1749C120.138 14.9961 120.603 14.9055 121.073 14.9093C123.052 14.9093 124.247 16.1719 124.247 18.2575V18.7149H119.225V18.7884C119.203 19.0322 119.236 19.2776 119.321 19.5089C119.407 19.7402 119.543 19.9523 119.721 20.1315C119.9 20.3107 120.116 20.4531 120.357 20.5495C120.598 20.6458 120.857 20.6941 121.119 20.6911C121.455 20.7286 121.795 20.6721 122.096 20.5287C122.397 20.3853 122.645 20.1616 122.81 19.8859L124.163 19.886ZM119.225 17.7455H122.816C122.834 17.5227 122.801 17.2989 122.721 17.0885C122.641 16.878 122.514 16.6856 122.349 16.5236C122.185 16.3616 121.985 16.2335 121.765 16.1476C121.544 16.0618 121.306 16.02 121.067 16.0249C120.824 16.0218 120.583 16.0642 120.358 16.1496C120.133 16.235 119.929 16.3616 119.757 16.522C119.585 16.6824 119.45 16.8734 119.359 17.0835C119.267 17.2936 119.222 17.5187 119.225 17.7455H119.225Z" fill="white" />
        <path d="M130.113 15.0371H133.32C134.711 15.0371 135.527 15.6954 135.527 16.726C135.529 17.053 135.411 17.3708 135.192 17.6262C134.974 17.8816 134.668 18.0591 134.326 18.129V18.2327C134.741 18.2572 135.13 18.4297 135.413 18.7147C135.696 18.9996 135.851 19.3752 135.846 19.7639C135.846 20.9227 134.913 21.6789 133.352 21.6789H130.113V15.0371ZM131.505 16.0433V17.7631H132.896C133.744 17.7631 134.15 17.4585 134.15 16.8967C134.15 16.3789 133.797 16.0432 133.052 16.0432L131.505 16.0433ZM131.505 18.7268V20.6728H133.169C133.999 20.6728 134.443 20.3307 134.443 19.7027C134.443 19.0379 133.94 18.7268 132.993 18.7268L131.505 18.7268Z" fill="white" />
      </svg>
    </a>
  )
}
