import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { AppGallery, AppStoreIcon, Button, Footer, Header, Input, LogoBigIcon, PlayStoreIcon, Point, Pulse, TextModal } from '../../../common';
import iPhone from '../../../assets/images/iPhone-12-Mockup.png';
import iPhoneL from '../../../assets/images/iPhone-mock-l.png';
import axios from 'axios';
import { toggleTextModal } from '../../../store';

export const Desktop: FC = () => {

  const [phoneNumber, setPhoneNumber] = useState('');

  const isBtnDisabled = useMemo(() => phoneNumber.replace(/[^0-9]/g, "").length !== 10, [phoneNumber]);

  const handleClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) = useCallback(e => {
    e.preventDefault();
    (window as any).ym(86361007, 'reachGoal', 'ssylka_iz_sms');
    setPhoneNumber('');
    toggleTextModal();
    const promo = new URLSearchParams(window.location.search).get('promo');
    axios.post('https://api.drsvat.com/api/v1/invite/send-sms', {
      phone: `+7 ${phoneNumber}`,
      promo
    })
  }, [phoneNumber]);

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback(e => setPhoneNumber(e.target.value), []);

  return (
    <Fragment>
      <Header />

      <TextModal>Спасибо за Ваше обращение, ссылка была отправлена вам на телефон</TextModal>

      <div className="main-page__desktop-container">

        <div className="main-page__desktop-left">
          <div className="main-page__desktop-header">
            <LogoBigIcon />

            <h1>
              Осознанные знакомства <br />
              <span>Профессиональный поиск второй половинки</span>
            </h1>
          </div>

          <div className="main-page__desktop-callback">
            <p className="text text_l">
              Введи номер телефона, в смс придет <br /> ссылка на скачивание приложения
            </p>

            <div className="main-page__desktop-callback-controls">
              <Input onChange={handleInputChange} value={phoneNumber} />

              <Button onClick={handleClick} disabled={isBtnDisabled}>Скачать</Button>
            </div>
          </div>

          <div className="main-page__desktop-apps">
            <div className="main-page__desktop-socials">
              <AppStoreIcon />
              <PlayStoreIcon />
            </div>
          </div>
        </div>

        <div className="main-page__desktop-right">
          <Pulse className="main-page__desktop-pulse" />
          <Point iconType="shield" className="main-page__desktop-point main-page__desktop-point-one">
            <strong>Мы №1 по безопасности.</strong> <br /> Двойная верификация пользователей
          </Point>
          <Point iconType="calendar" className="main-page__desktop-point main-page__desktop-point-two" reverse={true}>
            <strong>До 90 анкет в месяц.</strong> <br /> Каждый день – 3 кандидата
          </Point>
          <img className="main-page__desktop-point main-page__desktop-point-main" src={iPhone} alt="iphone mock" />
          <img className="main-page__desktop-point main-page__desktop-point-main-l" src={iPhone} alt="iphone mock" />
          <Point iconType="person" className="main-page__desktop-point main-page__desktop-point-three">
            <strong>Персональный подход.</strong> <br /> Подбор кандидатов <br /> по 12 параметрам
          </Point>
          <Point iconType="heart" className="main-page__desktop-point main-page__desktop-point-four" reverse={true}>
            <strong>Нет навязанных услуг.</strong> <br /> Оплата за свидания
          </Point>
        </div>
      </div>

      <Footer />
    </Fragment>
  )
};
