import React, { FC, Fragment, useMemo, useState, useCallback } from 'react';
import axios from 'axios';

import { AppGallery, AppStoreIcon, Button, Footer, Header, Input, LogoBigIcon, PlayStoreIcon, Point } from '../../../common';
import { toggleTextModal } from '../../../store';

export const Mobile: FC = () => {

  const [phoneNumber, setPhoneNumber] = useState('');

  const isBtnDisabled = useMemo(() => phoneNumber.replace(/[^0-9]/g, "").length !== 10, [phoneNumber]);

  const handleClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) = useCallback(e => {
    e.preventDefault();
    (window as any).ym(86361007, 'reachGoal', 'ssylka_iz_sms');
    setPhoneNumber('');
    toggleTextModal();
    const promo = new URLSearchParams(window.location.search).get('promo');
    axios.post('https://api.drsvat.com/api/v1/invite/send-sms', {
      phone: `+7 ${phoneNumber}`,
      promo
    })
  }, [phoneNumber]);

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback(e => setPhoneNumber(e.target.value), []);


  const Logo = useMemo(() => {
    if (/Android/i.test(navigator.userAgent)) return <PlayStoreIcon />

    return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? <AppStoreIcon /> : <AppGallery />
  }, []);

  return (
    <Fragment>
      <Header />

      <div className="main-page__desktop-header">
        <LogoBigIcon />

        <h1>
          Осознанные знакомства
          <span>Профессиональный <br /> поиск второй половинки</span>
        </h1>
      </div>

      <div className="main-page__desktop-callback">
        <p className="text text_l">
          Введи номер телефона, в смс придет <br /> ссылка на скачивание приложения
        </p>

        <div className="main-page__desktop-callback-controls">
          <Input onChange={handleInputChange} value={phoneNumber} />

          <Button onClick={handleClick} disabled={isBtnDisabled}>Скачать</Button>
        </div>
      </div>


      <div className="main-page__phone-container">
        <div className="main-page__points-container">
          <Point iconType="shield">
            <strong>Мы №1 по безопасности.</strong> <br /> Двойная верификация пользователей
          </Point>
          <Point iconType="calendar" reverse={true}>
            <strong>Персональный подход.</strong> <br /> Подбор кандидатов <br /> по 12 параметрам
          </Point>
          <Point iconType="person">
            <strong>До 90 анкет в месяц.</strong> <br /> Каждый день – 3 кандидата
          </Point>
          <Point iconType="heart" reverse={true}>
            <strong>Нет навязанных услуг.</strong> <br /> Оплата за свидания
          </Point>
        </div>

      </div>

      <Footer />
    </Fragment>
  )
};
