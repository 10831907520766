import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { MainLogo } from '../../icons';

import './Header.scss';

export const Header: FC = () => (
  <div className="header">
    <Link to="/">
      <MainLogo />
    </Link>

    <h6>
      Онлайн-агентство <br /> знакомств
    </h6>
    
  </div>
);
