import React, { FC } from 'react';
import { Footer, Header } from '../../common';

export const PolicyPage: FC = () => (
  <div className="policy-page docs-page">
    <Header />
    <div className="policy-page__container docs-page__container">
      <br /><br /><br />
      <br />
      <br />
      <h2 className="common-page__main-header">Политика конфиденциальности</h2>
      <br /><br /><br />

      <p className="docs-page__text">
        Настоящее соглашение определяет Политику Общества с ограниченной ответственностью, «Доктор Сват» (ОГРН 1203600028774) (далее - «Правообладатель») в отношении обработки персональных данных и реализации требований к защите персональных данных (далее –«Политика») в соответствии с требованиями ст. 18.1. Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>
      <br /><br /><br />

      <h2 className="docs-page__header">1. Определение терминов</h2><br />

      <p className="docs-page__text">
        1.1 В настоящей Политике конфиденциальности используются следующие термины:
      </p> <br />

      <p className="docs-page__text">
        1.1.1. Сервис – сервис автоматизированной многоуровневой проверки и подбора наиболее подходящих Пользователю Кандидатов.
      </p> <br />

      <p className="docs-page__text">
        1.1.2. «Администрация сервиса (далее – Администрация сайта)» – уполномоченные сотрудники на управление всех существующих на данный момент сервисов, действующие от имени Правообладателя, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      </p> <br />

      <p className="docs-page__text">
        1.1.3. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
      </p> <br />

      <p className="docs-page__text">
        1.1.4. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      </p> <br />

      <p className="docs-page__text">
        1.1.5. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
      </p> <br />

      <p className="docs-page__text">
        1.1.6. «Платформа» - программно-аппаратные средства Правообладателя, интегрированные с сайтом и (или) мобильным приложением.
      </p> <br />

      <p className="docs-page__text">
        1.1.7. Пользователь Сервиса Правообладателя и всех существующих на данный момент сервисов (далее  Пользователь)» – лицо, имеющее доступ к Сайту <a href="https://drsvat.com/"> drsvat.com </a>, мобильному приложению «Доктор Сват», посредством сети Интернет.
      </p> <br />

      <p className="docs-page__text">
        1.1.8. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
      </p> <br />

      <p className="docs-page__text">
        1.1.9. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
      </p> <br /> <br />


      <h2 className="docs-page__header">2. Общие положения</h2><br />


      <p className="docs-page__text">
        2.1. Использование Пользователем сервиса означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
      </p> <br />

      <p className="docs-page__text">
        2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта <a href="https://drsvat.com/"> drsvat.com </a> а так же всех существующих на данный момент сервисов Правообладателя
      </p> <br />

      <p className="docs-page__text">
        2.3. Настоящая Политика конфиденциальности применяется только к программно-аппаратным средствам Правообладателя интегрированных с сайтом <a href="https://drsvat.com/"> drsvat.com </a> и (или) мобильным приложениям, Правообладатель не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте <a href="https://drsvat.com/"> drsvat.com </a>
      </p> <br />

      <p className="docs-page__text">
        2.4. Администрация сайта проверяет достоверность персональных данных, предоставляемых Пользователем сервиса <a href="https://drsvat.com/"> drsvat.com </a>, путем контакта с Пользователем посредством телефонного звонка.
      </p> <br /><br />


      <h2 className="docs-page__header">3. Предмет политики конфиденциальности </h2><br />


      <p className="docs-page__text">
        3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта  по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте <a href="https://drsvat.com/"> drsvat.com </a>  или при оформлении заявки для приобретения услуг Правообладателя.
      </p> <br />

      <p className="docs-page__text">
        3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте <a href="https://drsvat.com/"> drsvat.com </a> в мобильном приложении «Доктор Сват» и включают в себя следующую информацию:
      </p> <br />

      <p className="docs-page__text">
        3.2.1. имя;
      </p> <br />

      <p className="docs-page__text">
        3.2.2. контактный телефон Пользователя;
      </p> <br />

      <p className="docs-page__text">
        3.2.3. адрес электронной почты (e-mail);
      </p> <br />

      <p className="docs-page__text">
        3.2.3.  возраст;
      </p> <br />

      <p className="docs-page__text">
        3.2.4.  дата и время рождения;
      </p> <br />

      <p className="docs-page__text">
        3.2.5.  семейное положение;
      </p> <br />

      <p className="docs-page__text">
        3.2.6.  регион проживания;
      </p> <br />

      <p className="docs-page__text">
        3.3. Сайт <a href="https://drsvat.com/"> drsvat.com </a> защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):
      </p> <br />

      <p className="docs-page__text">
        IP адрес;<br />
        информация из cookies;<br />
        информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);<br />
        время доступа;<br />
        адрес страницы, на которой расположен рекламный блок;<br />
        реферер (адрес предыдущей страницы).
      </p> <br />

      <p className="docs-page__text">
        3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта <a href="https://drsvat.com/"> drsvat.com </a>.
      </p> <br />

      <p className="docs-page__text">
        3.3.2. Сайт <a href="https://drsvat.com/"> drsvat.com </a> осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
      </p> <br />

      <p className="docs-page__text">
        3.4. Любая иная персональная информация не оговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
      </p> <br />

      <br />


      <h2 className="docs-page__header">4. Цели сбора персональной информации пользователя</h2><br />


      <p className="docs-page__text">
        4.1. Персональные данные Пользователя Администрация сайта <a href="https://drsvat.com/"> drsvat.com </a> может использовать в целях:
      </p> <br />

      <p className="docs-page__text">
        4.1.1. Идентификации Пользователя, зарегистрированного на сайте <a href="https://drsvat.com/"> drsvat.com </a>, для оформления заказа.
      </p> <br />

      <p className="docs-page__text">
        4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта <a href="https://drsvat.com/"> drsvat.com </a>, оказания услуг, обработка запросов и заявок от Пользователя.
      </p> <br />

      <p className="docs-page__text">
        4.1.3. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
      </p> <br />

      <p className="docs-page__text">
        4.1.4. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.
      </p> <br />

      <p className="docs-page__text">
        4.1.5. Уведомления Пользователя Сайта <a href="https://drsvat.com/"> drsvat.com </a>  о состоянии Поиска Кандидатов.
      </p> <br />

      <p className="docs-page__text">
        4.1.6. Обработки и получения платежей Пользователя.
      </p> <br />

      <p className="docs-page__text">
        4.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта <a href="https://drsvat.com/"> drsvat.com </a>.
      </p> <br />

      <p className="docs-page__text">
        4.1.8. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Агентства знакомств «Dr.Svat».
      </p> <br />

      <p className="docs-page__text">
        4.1.9. Осуществления рекламной деятельности с согласия Пользователя.
      </p> <br />

      <p className="docs-page__text">
        4.1.10. Публиковать контактные данные Пользователя Кандидатам, при условии взаимного согласия выраженного как со стороны Пользователя, так и со стороны Кандидата.
      </p> <br />

      <br />


      <h2 className="docs-page__header">5. Способы и сроки обработки персональной информации</h2><br />


      <p className="docs-page__text">
        5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
      </p> <br />

      <p className="docs-page__text">
        5.2 Пользователь вправе отозвать согласие на обработку персональных данных путем подачи на сайт <a href="https://drsvat.com/"> drsvat.com </a> заявки через обратную связь, или письменно на почту info@<a href="https://drsvat.ru/"> drsvat.ru </a>.
      </p> <br />

      <p className="docs-page__text">
        5.3. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные Кандидату или же третьим лицам, только с согласия Пользователя.
      </p> <br />

      <p className="docs-page__text">
        5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
      </p> <br />

      <br />


      <h2 className="docs-page__header">6. Обязательства сторон</h2><br />

      <p className="docs-page__text">
        6.1. Пользователь обязан:
      </p> <br />

      <p className="docs-page__text">
        6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом <a href="https://drsvat.com/"> drsvat.com </a> и мобильными приложениями «Доктор Сват»
      </p> <br />

      <p className="docs-page__text">
        6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
      </p> <br />

      <p className="docs-page__text">
        6.2. Администрация сайта обязана:
      </p> <br />

      <p className="docs-page__text">
        6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
      </p> <br />

      <p className="docs-page__text">
        6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.3. настоящей Политики Конфиденциальности.
      </p> <br />

      <p className="docs-page__text">
        6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
      </p> <br />

      <br />

      <h2 className="docs-page__header">7. Ответственность сторон</h2><br />

      <p className="docs-page__text">
        7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
      </p> <br />

      <p className="docs-page__text">
        7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
      </p> <br />

      <p className="docs-page__text">
        7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
      </p> <br />

      <p className="docs-page__text">
        7.2.3. Была разглашена с согласия Пользователя.
      </p> <br />

      <br />

      <h2 className="docs-page__header">8. Разрешение споров</h2><br />

      <p className="docs-page__text">
        8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта <a href="https://drsvat.com/"> drsvat.com </a>  и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
      </p> <br />

      <p className="docs-page__text">
        8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
      </p> <br />

      <p className="docs-page__text">
        8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.
      </p> <br />

      <p className="docs-page__text">
        8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.
      </p> <br />

      <h2 className="docs-page__header">9. Дополнительные условия</h2><br />

      <p className="docs-page__text">
      9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
      </p> <br />

      <p className="docs-page__text">
      9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте <a href="https://drsvat.com/"> drsvat.com </a>, если иное не предусмотрено новой редакцией Политики конфиденциальности.
      </p> <br />

    </div>
    <Footer />
  </div>
);
