import Alex from '../../../assets/images/team/alexandr.jpeg';
import Alexey from '../../../assets/images/team/alexey.webp';
import Astrolog from '../../../assets/images/team/astrolog.webp';
import Design from '../../../assets/images/team/design.webp';
import DevA from '../../../assets/images/team/devA.png';
import DevL from '../../../assets/images/team/devL.png';
import DevM from '../../../assets/images/team/devM.png';
import DevS from '../../../assets/images/team/devS.png';
import Nastya from '../../../assets/images/team/nastya.jpeg';
import Tatyana from '../../../assets/images/team/tatyana.webp';
import Tolik from '../../../assets/images/team/tolik.webp';
import Vlad from '../../../assets/images/team/vlad.png';

import { Avatar, Chip } from '../../../common/models';

export const avatars: Avatar[] = [
  {
    src: Tolik,
    job: 'CEO & со-founder',
    name: 'Анатолий'
  },
  {
    src: Alexey,
    job: 'СTО & со-founder',
    name: 'Алексей'
  },
  {
    src: Vlad,
    job: 'Product owner & co-founder',
    name: 'Владислав'
  },
  {
    src: Alex,
    job: 'Director of marketing',
    name: 'Александр'
  },
  {
    src: Nastya,
    job: 'PR-director',
    name: 'Анастасия'
  },
  {
    src: DevA,
    job: 'Lead Developer',
    name: 'Михаил'
  },
  {
    src: DevL,
    job: 'Developer',
    name: 'Леонид'
  },
  {
    src: Tatyana,
    job: 'Moderator',
    name: 'Татьяна'
  },
  {
    src: Design,
    job: 'Designer',
    name: 'Алексей'
  },
  {
    src: DevS,
    job: 'Developer',
    name: 'Александр'
  },
  {
    src: DevM,
    job: 'Moderator',
    name: 'Михаил'
  },
  {
    src: Astrolog,
    job: 'Developer, Astrologer',
    name: 'Андрей'
  },
];

export const companyChips: Chip[] = [
  {
    title: '1. Dr.Svat — №1 по безопасности',
    text: 'Защищаем от фейков и мошенников. Каждый пользователь при регистрации проходит двойную верификацию личности и ручную модерацию. <br /> <br />'
  },
  {
    title: '2. Индивидуальный подбор по 12 параметрам',
    text: `- 4  социально-физиологических и 8 астрологических, дополнительно в анкете указано еще 8 параметров для ознакомления. <br /> <br />
    - Алгоритм постоянно обучается с помощью нейронных сетей, анализирует ответы и с каждым разом улучшает выдачу кандидатов. <br /> <br />`
  },
  {
    title: '3. Один день — три кандидата',
    text: `Это экономит время — никакого бесконечного выбора и переписок. Скажешь, мало? Но ведь это до 90 кандидатов в месяц, 1080 в год. На взаимодействие с приложением нужна всего одна минута в день. <br />
    PUSH-уведомления приходят утром. <br /> <br />`
  },
  {
    title: '4. Оплата за результат — назначенное свидание',
    text: 'У нас все честно — в Dr.Svat нет навязанных услуг. За свидание платят обе стороны. Когда за встречу с тобой заплатили, ценность возрастает. Кроме того, это дополнительный фильтр по безопасности. <br /> <br />'
  },
  {
    title: '5. Анализируем астрологическую совместимость',
    text: `На одном из этапов используем профессиональную астрологию, делаем анализ совместимости пары по натальным картам. Для этого мы диджитализировали алгоритм одной из самых популярных синастрических систем — совместимость на основе 8 важных аспектов совместной жизни. <br /> <br />

    1. Варна — <i>мышление и совместимость на интеллектуальном уровне.</i> <br /><br /> 
    2. Вашья — <i>сила взаимного влечения партнёров.</i> <br /><br />
    3. Тара (Дина)  — <i>поведение и согласие в повседневной жизни.</i> <br /><br />
    4. Йони — <i>сексуальная  и физическая совместимость.</i> <br /><br /> 
    5. Грахамаитри — <i>психологическая совместимость и эмоциональный комфорт.</i> <br /><br /> 
    6. Гана — <i>совместимость характеров и темперамент.</i> <br /><br />
    7. Раши — <i>процветание, благосостояние и удача.</i> <br /><br />
    8. Нади — <i>энергетическая совместимость, наследственность.</i> <br /><br />`
  },
  {
    title: '6. Помогаем начать диалог',
    text: 'Убираем психологические барьеры и избавляем от неловкости первого шага в общении — после обоюдной симпатии, наш чат-бот в WhatsApp первый начинает диалог между вами. <br /> <br />'
  },
  {
    title: '7. Сохраняем все твои знакомства в истории',
    text: 'В личном кабинете отображена статистика твоих знакомств и свиданий. Ты всегда можешь передумать по поводу кандидата, которому в первый раз сказал(а) нет или дать ответ случайно пропущенному кандидату. <br /> <br />'
  },
  {
    title: '8. Помогаем и подсказываем 24/7',
    text: 'У нас самая классная служба поддержки. Ты можешь написать нам в любом удобном для тебя канале связи: WhatsApp, Telegram, Viber, Vk, и мы обязательно ответим. <br /> <br />'
  },
];
