import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { GrayTie, SocialIcon } from '../../icons';

import './Footer.scss';

export const Footer: FC = () => (
  <div className="footer">
    <div className="footer__head">
      <div className="footer__head-line"></div>
      <GrayTie />
      <div className="footer__head-line"></div>
    </div>

    <div className="footer__line-container">
      <div className="footer__line">
        <Link to="/terms" className="footer__line-item text text_s">
          Публичная оферта
        </Link>

        <Link to="/faq" className="footer__line-item text text_s">
          FAQ
        </Link>
      </div>

      <div className="footer__line">
        <Link to="/privacy" className="footer__line-item text text_s">
          Политика конфиденциальности
        </Link>

        <Link to="/bonus" className="footer__line-item text text_s">
          Партнерская программа
        </Link>
      </div>

      {/* <div className="footer__line"> */}
      {/* <Link to="" className="footer__line-item text text_s">
          Правила оплаты
        </Link>

        <Link to="" className="footer__line-item text text_s">
          Советы
          побезопасности
        </Link> */}
      {/* </div> */}

      {/* <div className="footer__line"> */}

      {/* <Link to="" className="footer__line-item text text_s">
          Наши пары
        </Link> */}
      {/* </div> */}

      <div className="footer__line">
        {/* <Link to="" className="footer__line-item text text_s">
          Реквизиты
        </Link> */}
        <Link to="/company" className="footer__line-item text text_s">
          Об агентстве
        </Link>
        <Link to="/geography" className="footer__line-item text text_s">
          Города
        </Link>


      </div>

      <div className="footer__line">
        <a href="mailto:info@drsvat.ru" className="footer__line-item text text_s">
          info@drsvat.ru
        </a>
        {/* <Link to="/rates" className="footer__line-item text text_s">
          Тарифы
        </Link> */}
      </div>
    </div>

    <div className="footer__desktop-alignment-block">
      <div className="footer__line-container_desktop">
        <div className="footer__line">
          <Link to="/terms" className="footer__line-item text text_s">
            Публичная оферта
          </Link>

          <Link to="/faq" className="footer__line-item text text_s">
            FAQ
          </Link>

          <Link to="/company" className="footer__line-item text text_s">
            Об агентстве
          </Link>
        </div>
        <div className="footer__line-container_desktop">
          <div className="footer__line">
            <Link to="/privacy" className="footer__line-item text text_s">
              Политика конфиденциальности
            </Link>

            <Link to="/bonus" className="footer__line-item text text_s">
              Партнерская программа
            </Link>

            <Link to="/geography" className="footer__line-item text text_s">
              Города
            </Link>

            {/* <Link to="" className="footer__line-item text text_s">
              Наши пары
          </Link> */}
          </div>
        </div>

        <div className="footer__line-container_desktop">
          <div className="footer__line">
            {/* <Link to="/rates" className="footer__line-item text text_s">
              Тарифы
            </Link> */}


          </div>
        </div>

        <div className="footer__line-container_desktop">
          {/* <div className="footer__line">
            <a href="mailto:info@drsvat.ru" className="footer__line-item text text_s">
              Реквизиты
          </a>
          </div> */}
        </div>
      </div>


      <div className="footer__desktop-info">
        <a className="footer__email-text text text_s" href="mailto:info@drsvat.ru">info@drsvat.ru</a>
        <div className="footer__social">
          <SocialIcon name="youtube" />
          <SocialIcon name="instagram" />
          <SocialIcon name="facebook" />
          <SocialIcon name="vk" />
          <SocialIcon name="ok" />
        </div>
      </div>
    </div>

  </div>
);
