import React, { FC } from 'react';
import { Circle, Footer, Header } from '../../common';
import Cities from '../../assets/images/mini-cities.png';

import { CityBlock, CityItem } from './Subs';
import { cities } from './data';

import './GeographyPage.scss';

export const GeographyPage: FC = () => (
  <div className="geography-page common-page">
    <Header />
    <div className="geography-page__container common-page__container">
      <div className="geography-page__map-block">
        {cities.map((city, index) => <CityItem key={index} {...city} />)}
      </div>
      <Circle className="geography-page__circle geography-page__circle_ms" count={2} text="города" />
      <div className="geography-page__text-container">
        <h2 className="common-page__main-header geography-page__main-header">География проекта</h2>

        <div className="common-page__delimiter geography-page__delimiter"></div>

        <p className="common-page__text geography-page__text">
          Пока мы работаем в Москве и Санкт-Петербурге. В 2021 году собираемся открыться еще в 17 городах России.
        </p>
      </div>

      <img src={Cities} alt="Города" className="geography-page__image d-none_s" />

      <div className="geography-page__city-block">
        <CityBlock />
        <Circle className="geography-page__circle" count={2} text="города" />
      </div>
    </div>
    <Footer />
  </div>
);
