import React, { FC } from 'react';
import { Footer, Header } from '../../common';

export const OfertaPage: FC = () => (
  <div className="oferta-page docs-page">
    <Header />
    <div className="oferta-page__container docs-page__container">
      <br /><br /><br />
      <br />
      <br />
      <h2 className="common-page__main-header">Соглашение об использовании сервиса</h2>
      <br /><br /><br />

      <h2 className="docs-page__header">1. Общие положения</h2><br />

      <p className="docs-page__text">
        В соответствии с условиями настоящего Соглашения, далее по тексту именуемое «Соглашение», Общество с ограниченной ответственностью «Доктор Сват» (ОГРН 1203600028774) (далее - «Правообладатель») предоставляет пользователям право пользования возможностями Сервиса. Все существующие на данный момент сервисы, а также любое развитие их и/или добавление новых, в том числе изменение их стоимости, является предметом Соглашения.
      </p> <br />

      <p className="docs-page__text">
        1.1. В настоящем Соглашении и связанных с ним отношениях Сторон применяются следующие термины и определения:
      </p> <br />

      <p className="docs-page__text">
        а) Сервис – сервис автоматизированной многоуровневой проверки и подбора наиболее подходящих Пользователю Кандидатов, определяемых из числа других Пользователей, для целей знакомства Пользователя и Кандидата. Доступ к Сервису осуществляется Пользователем посредством сайта <a href="https://drsvat.com/"> drsvat.com </a>, мобильного приложения «Доктор Сват» для мобильных операционных системы Apple iOS. По итогам работы Сервиса Пользователь получает доступ к полным анкетным данным Кандидата, подобранным с использованием Платформы, при условии взаимного согласия выраженного как со стороны Пользователя, так и со стороны Кандидата.
      </p> <br />

      <p className="docs-page__text">
        б) Платформа — программно-аппаратные средства Правообладателя, интегрированные с сайтом и (или) мобильным приложением.
      </p> <br />

      <p className="docs-page__text">
        в) Пользователь – дееспособное, совершеннолетнее лицо, заключившее настоящее Соглашение с Правообладателем в своем интересе в соответствии с требованиями действующего законодательства и настоящего Соглашения. Термин употребляется для обозначения лиц и мужского и женского пола.
      </p> <br />

      <p className="docs-page__text">
        г) Личный кабинет – персональный раздел Сайта, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации в Сервисе. Личный кабинет предназначен для использования функциональных возможностей Сервиса, а также хранения персональной информации Пользователя и получения уведомлений.
      </p> <br />

      <p className="docs-page__text">
        д) Кандидат – лицо, разместившее информацию о себе, включая контактную информацию, в сети Интернет с целью знакомства с другими Пользователями, с которым взаимодействует Сервис в автоматическом режиме по заданию Пользователя и/или Пользователь посредством Сервиса. Термин употребляется для обозначения лиц и мужского, и женского пола.
      </p> <br />

      <p className="docs-page__text">
        е) Анкета Кандидата – персональные данные другого Пользователя, содержащие изображение этого пользователя, основные социально-физиологические параметры и его контактные данные. Пользователь получает доступ к полной Анкете Кандидата, только при получении согласия Кандидата. Во всех остальных случаях Пользователю предоставляются сокращенные Анкеты Кандидатов, без указания контактных данных Кандидата (Сокращенная Анкета кандидата).
      </p> <br />

      <p className="docs-page__text">
        ж) Знакомство – подбор наиболее подходящих Пользователю Кандидатов, определяемых из числа других Пользователей, для целей свидания Пользователя и Кандидата.
      </p> <br />

      <p className="docs-page__text">
        з) Свидание – доступ к полным анкетным данным Кандидата, подобранным с использованием Платформы, при условии взаимного согласия выраженного как со стороны Пользователя, так и со стороны Кандидата.
      </p> <br />

      <p className="docs-page__text">
        и) Куратор – менеджер Сервиса, который оказывает личное консультирование Пользователя по работе сервиса и (или) личным данным предложенного Кандидата.
      </p> <br />

      <p className="docs-page__text">
        к) Тариф – плата за пользование Сервисом.  Правообладатель имеет право установить дифференцированные Тарифы для различных групп Пользователей, и для разного объема услуг, оказываемых в рамках Сервиса. Действие Тарифа для пользователя, оплатившего Сервис бессрочно.  Тарифы за пользование Сервисом могут быть изменены Правообладателем без предварительного уведомления Пользователя в одностороннем порядке. Актуальные Тарифы, доступные для различных категорий Пользователей размещены по адресу: <a href="https://drsvat.com/"> https://drsvat.com/tarify </a>
      </p> <br /> <br />

      <p className="docs-page__text">
        1.2. В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или определения в тексте Соглашения следует руководствоваться его буквальным толкованием, законодательством Российской Федерации и обычаями делового оборота.
      </p> <br />

      <p className="docs-page__text">
        1.3. Использование Клиентом Сервиса осуществляется в пределах его объявленных функциональных возможностей, включая:
      </p> <br />

      <ul className="docs-page__list docs-page__text">
        <li>
          оплата услуг Сервиса
        </li>
        <li>
          регистрация и/или авторизация в Сервисе;
        </li>
        <li>
          создание анкеты для поиска наиболее подходящих Кандидатов, получение рекомендаций по размещению фотографий и заполнению сведений о себе для целей поиска потенциальных Кандидатов;
        </li>
        <li>
          проверка соответствия сведений (ФИО, возраст, семейное положение, регион проживания) предоставленных при регистрации и подлежащим включению в Анкету Пользователя, сведениям, указанным в паспорте Пользователя и (или)Кандидата;
        </li>
        <li>
          получение данных о подходящих Кандидатах с использованием Сервиса;
        </li>
        <li>
          получение иных рекомендаций в соответствии с назначением Сервиса.
        </li>
      </ul> <br />

      <p className="docs-page__text">
        1.4. Воспользовавшись любой из указанных выше возможностей по использованию Сервиса, Пользователь подтверждает, что:
      </p> <br />

      <p className="docs-page__text">
        а) Ознакомился с условиями настоящего Соглашения в полном объеме до начала использования Сервиса.
      </p> <br />

      <p className="docs-page__text">
        б) Принимает все условия настоящего Соглашения полном объеме без каких-либо изъятий и ограничений и обязуется их соблюдать или прекратить использование Сервиса. Пользователь не согласен с условиями настоящего Соглашения или не имеет права на заключение договора на его основе, ему следует незамедлительно прекратить любое использование Сервиса.
      </p> <br />

      <p className="docs-page__text">
        в) Соглашение (в том числе любая из его частей) может быть изменено Правообладателем без какого-либо специального уведомления. Новая редакция Соглашения и/или указанных в нем Обязательных документов вступает в силу с момента размещения на сайте Правообладателя либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения и/или указанных в нем обязательных для Сторон документов.
      </p> <br /><br />



      <h2 className="docs-page__header">2. Общие условия использования Сервиса</h2><br />



      <p className="docs-page__text">
        2.1. Для использования Сервиса Пользователь должен пройти процедуру регистрации. К использованию Сервиса допускаются лица, достигшие 18-ти лет, дееспособные, не состоящие в официальном браке, несудимые. Не допускается использование Пользователем Сервиса с целью извлечения прибыли.
      </p> <br />

      <p className="docs-page__text">
        2.2. В ходе регистрации Пользователь обязуется предоставить сведения, характеризующие его (пол, возраст, особенности внешности, наличие/отсутствие вредных привычек, вероисповедание, место постоянного проживания), предоставить минимум одну фотографию на которой сам Пользователь виден чётко и ясно.  Пользователь обязуется сообщить свои контактные данные, подлежащие раскрытию другим пользователям – номер мобильного телефона и адрес электронной почты.
      </p> <br />

      <p className="docs-page__text">
        2.3. В ходе регистрации Пользователь предоставляет свои пожелания к Кандидату (пол, возраст, наличие детей, место постоянного проживания, вероисповедание, наличие/отсутствие вредных привычек, уровень дохода)
      </p> <br />

      <p className="docs-page__text">
        2.4. Отказ Пользователя от передачи Правообладателю необходимых сведений или документов, влечет невозможность использования Сервисом. Список необходимых документов может быть изменен Правообладателем в одностороннем порядке. Правообладатель имеет право самостоятельно проводить проверку полноты и достоверности предоставленных Клиентом сведений, в том числе с использованием сторонних сервисов и третьих лиц.
      </p> <br />

      <p className="docs-page__text">
        2.5. Пользователь обязуется поддерживать актуальность введенной при регистрации информации весь период пользования Сервисом и вносить соответствующие изменения при необходимости. Правообладатель не несет ответственности за невозможность подбора подходящих Пользователю Кандидатов, связанную с недостоверностью или неактуальностью предоставленной Пользователем информации.
      </p> <br />

      <p className="docs-page__text">
        2.6. Пользователь самостоятельно обеспечивает конфиденциальность выбранного при регистрации пароля, а также несет полную ответственность за его надежность (устойчивость к подбору).
      </p> <br />

      <p className="docs-page__text">
        2.7. Если Пользователем при регистрации была предоставлена неверная информацию или у Правообладателя есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна, Правообладатель имеет право по своему усмотрению заблокировать либо удалить Личный кабинет Пользователя, а также отказать Пользователю в использовании Сервиса полностью или в определенной части. При этом внесенная Пользователем оплата не подлежит возврату.
      </p> <br />

      <p className="docs-page__text">
        2.8. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать пользователя, Правообладатель вправе применить меры, указанные в п.2.7. Соглашения.
      </p> <br />

      <p className="docs-page__text">
        2.9. Пользователь обязуется выразить своё согласие на предложение раскрыть свои Полные Анкетные данные, либо дать отказ от такого раскрытия (отказаться от Свидания) не позднее 5 рабочих дней с момента подбора соответствующего Кандидата.  В случае если Пользователь более 5 (пяти) раз не даёт ответа (согласие или отказ) по Кандидатам, предложенным Сервисом, Правообладатель вправе удалить Анкету такого Пользователя, предварительно уведомив его о нарушении условий Сервиса. При этом уплаченные Пользователем денежные средства не возвращаются. Вместо удаления Анкеты Пользователя Правообладатель вправе приостановить процесс поиска Кандидатов для такого Пользователя.
      </p> <br />

      <p className="docs-page__text">
        2.10. Технические, организационные и коммерческие условия использования Сервиса могут доводиться до сведения Пользователей путем отдельного размещения на Сайте или путем нотификации Пользователей.
      </p> <br />

      <p className="docs-page__text">
        2.11. Правообладатель вправе устанавливать лимиты и вводить иные технические ограничения использования Сервиса, которые время от времени будут доводиться до сведения Пользователей в форме и способом по выбору Правообладателя.      </p> <br />

      <p className="docs-page__text">
        2.12. Частота предоставления подходящих Пользователю Кандидатов определяется Сервисом, в том числе исходя из фактического наличия Кандидатов, удовлетворяющих запросу Пользователя, сформулированному им при регистрации. При этом новый Кандидат предоставляется на рассмотрение Пользователя не чаще 2 (двух) Кандидатов за 1 (один) день.
      </p> <br />

      <br />


      <h2 className="docs-page__header">3. Гарантии Пользователя</h2><br />


      <p className="docs-page__text">
        Принимая условия настоящего Соглашения, Пользователь подтверждает и гарантирует что:
      </p> <br />

      <p className="docs-page__text">
        3.1. Он обладает всеми необходимыми правами и полномочиями для заключения Соглашения на использование Сервиса и его исполнения;
      </p> <br />

      <p className="docs-page__text">
        3.2. Использование Сервиса будет осуществляться исключительно для целей с другими Пользователями Сервиса, с соблюдением положений настоящего Соглашения, а равно требований законодательства и общепринятых норм морали и этики.
      </p> <br />

      <p className="docs-page__text">
        3.3.  Использование им Сервиса для конкретных целей не нарушает имущественных и/или личных неимущественных прав третьих лиц, а равно запретов и ограничений, установленных применимым правом, а также права государства вашего места нахождения, предоставляемые вами сведения, и материалы, включая фотографии не содержат сведений и/или образов, оскорбляющих честь, достоинство и нравственные чувства других Пользователей, а также информацию пропагандирующую насилие, экстремизм, порнографию, наркотики, расовую, национальную вражду, либо дискриминацию по иному признаку. Критерии оценки предоставляемых фотоматериалов определяются Правообладателем по собственному усмотрению. В случае предоставления Пользователем фотоматериалов, не соответствующих настоящему пункту Правообладатель вправе удалить их без предварительного уведомления Пользователя.
      </p> <br />

      <p className="docs-page__text">
        3.4. При предоставлении фотографий, на которых помимо Пользователя изображены другие лица, им были получены все необходимые разрешения на использование таких изображений.
      </p> <br />

      <p className="docs-page__text">
        3.5. В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Пользователь обязан незамедлительно сообщить об этом Правообладателю путем направления электронного письма с указанного при использовании Сервиса адреса электронной почты.
      </p> <br />

      <p className="docs-page__text">
        3.6. В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан при использовании Сервиса, Пользователь обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте Правообладателю путем направления электронного письма с нового адреса электронной почты.
      </p> <br /> <br />


      <h2 className="docs-page__header">4. Оплата Сервиса</h2><br />


      <p className="docs-page__text">
        4.1. Пользование Сервисом осуществляется на платной основе, согласно утверждённым Правообладателем Тарифам. Пользователь обязуется внести плату за пользование Сервисом при Знакомстве Пользователя и Кандидата (при условии взаимного согласия на раскрытие Сервисом Полной анкеты Пользователей друг-другу), либо посредством внесения предоплаты при покупке Тарифа, включающего в себя несколько предоплаченных Свиданий. В соответствии с положениями подпункта «ж» пункта 1.1. настоящего Соглашения, Правообладателем может устанавливаться различный размер Тарифов для различных категорий пользователей, вплоть до освобождения отдельных категорий пользователей от платы за пользования Сервисом.
      </p> <br />

      <p className="docs-page__text">
        4.2. По итогам работы Сервиса Пользователю в зависимости от приобретаемого им тарифа гарантированно предоставляется от 1 до 12 Анкет Кандидатов, подходящих под критерии, обозначенные Пользователем в соответствии с п. 2.3 Соглашения, в соответствии с выбранным тарифным планом, и выразивших согласие на раскрытие своих данных для Пользователя для последующего с ним взаимодействия (электронная переписка, звонок, встреча). Способ взаимодействия определяется Пользователем и Кандидатом самостоятельно. Отказ Пользователя и (или) Кандидата от взаимодействия после дачи согласия на раскрытие своих данных не даёт основания для возврата внесённой платы, аннулирования списания Свидания с Тарифа.
      </p> <br />

      <p className="docs-page__text">
        4.3. В случае досрочного отказа Пользователя от использования Сервиса до выполнения Правообладателем условий, указанных в п. 4.2 Соглашения, Пользователю возвращается 30% за неизрасходованные свидания в оплаченном им Тарифе. Удерживаемые Правообладателем 70% от стоимости оплаченного Пользователем Тарифа составляют расходы Правообладателя, фактически понесенные им на оказание услуг Пользователю. После выполнения Правообладателем условий, указанных в п. 4.2. Соглашения оплата не возвращается.
      </p> <br />

      <p className="docs-page__text">
        4.4. Досрочный отказ Пользователя от использования Сервиса осуществляется путём направления скан-образа письменного заявления Пользователя по адресу info@drsvat.ru.
      </p> <br />



      <h2 className="docs-page__header">5. Ограничения</h2><br />


      <p className="docs-page__text">
        Соглашаясь с условиями настоящего Соглашения, Пользователь понимает и признает, что:
      </p> <br />

      <p className="docs-page__text">
        5.1. Сервис предоставляется для использования на условиях «как есть», в связи с чем Пользователю не представляются какие-либо гарантии, что Сервис будет соответствовать вашим требованиям; услуги будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием Сервиса, будут точными и надежными; качество какого-либо продукта, услуги, информации, полученных с использованием Сервиса, будет соответствовать ожиданиям Пользователя; все ошибки в Контенте и/или программном обеспечении Сервиса будут исправлены. При обнаружении ошибок в работе Сервиса сообщите об этом Правообладателю по адресу, указанному в реквизитах или отдельно на Сайте для службы поддержки.
      </p> <br />

      <p className="docs-page__text">
        5.2. Поскольку Сервис находится на стадии постоянного дополнения и обновления новых функциональных возможностей, форма и характер предоставляемых услуг могут время от времени меняться без предварительного уведомления. Правообладатель вправе по собственному усмотрению прекратить (временно или окончательно) предоставление услуг (или каких-либо отдельных функций в рамках услуг) всем Пользователям вообще или вам, в частности, без вашего предварительного уведомления.
      </p> <br />

      <p className="docs-page__text">
        5.3. Пользователь не имеет права самостоятельно или с привлечением третьих лиц:
      </p> <br />

      <ul className="docs-page__list docs-page__text">
        <li>
          копировать (воспроизводить) в любой форме и способом входящие в состав Сервиса Правообладателя программы для ЭВМ и базы данных, включая любые их элементы, без получения предварительного письменного согласия их владельца;
        </li>
        <li>
          вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать, и производить иные аналогичные действия с Сервисом;
        </li>
        <li>
          создавать программные продукты и/или сервисы с использованием Сервиса без получения предварительного разрешения Правообладателя.
        </li>
      </ul> <br />

      <p className="docs-page__text">
        5.4. Пользователь несет всю ответственность за содержание сообщений, направляемых  Кандидатам, а также действия, совершаемые с полученной информацией и материалами о Кандидатах, и их соответствие требованиям применимого права.
      </p> <br />

      <p className="docs-page__text">
        5.5. Пользователь несет исключительную ответственность за любое нарушение обязательств, установленных настоящим Соглашением и/или применимым правом, а также за все последствия таких нарушений (включая любые убытки или ущерб, которые может понести Кандидат, Правообладатель и иные третьи лица).
      </p> <br />

      <p className="docs-page__text">
        5.6. В случае возникновения каких-либо претензий третьих лиц в отношении нарушения Пользователем любых имущественных и/или личных неимущественных прав третьих лиц, а равно установленных законодательством запретов или ограничений Пользователь обязан по требованию Правообладателя пройти официальную идентификацию, предоставив Правообладателю нотариально заверенное обязательство урегулировать возникшие претензии собственными силами и за свой счет с указанием своих паспортных данных.
      </p> <br />

      <p className="docs-page__text">
        5.7. В связи с установленной законодательством ответственностью за размещение и предоставление доступа к нелегальному Контенту, Правообладатель оставляет за собой право удалить любой Контент из Сервиса либо ограничить к нему доступ в одностороннем порядке.
      </p> <br />

      <p className="docs-page__text">
        5.8. В случае многократного или грубого нарушения условий настоящего Соглашения, и/или требований законодательства, Правообладатель оставляет за собой право заблокировать доступ к Сервису либо иным образом ограничить (прекратить) предоставление Пользователю услуг с использованием Сервиса без возвращения внесенной Пользователем оплаты.
      </p> <br />

      <p className="docs-page__text">
        5.9. В случае привлечения Правообладателя к ответственности или наложения на него взыскания в связи с допущенными вами нарушениями прав и/или интересов третьих лиц, а равно установленных законодательством запретов или ограничений, Пользователь обязан в полном объеме возместить убытки Правообладателя.
      </p> <br /> <br />


      <h2 className="docs-page__header">6. Уведомления</h2><br />


      <p className="docs-page__text">
        6.1. Пользователь соглашается получать от Правообладателя на указанный при работе с Сервисом адрес электронной почты и(или) номер мобильного телефона информационные электронные сообщения (далее — «нотификаторы») о важных событиях, происходящих в рамках Сервиса или в связи с ним.
      </p> <br />

      <p className="docs-page__text">
        6.2. Правообладатель вправе использовать нотификаторы для информирования Пользователя об изменениях и новых возможностях Сервиса и/или об изменении Соглашения.
      </p> <br />

      <p className="docs-page__text">
        6.3. При условии взаимного согласия Пользователя и Кандидата на Свидание, Пользователь даёт свое согласие на взаимодействие с Кандидатом посредством мессенджеров WhatsApp и (или) Telegram.
      </p> <br />

      <br />


      <h2 className="docs-page__header">7. Прочие условия</h2><br />


      <p className="docs-page__text">
        7.1. Соглашение вступает в силу с момента выражения Пользователем согласия со всеми условиями Соглашения. Пользователь считается принявшим условия Соглашения в полном объеме после прохождения процедуры регистрации. Регистрация Пользователя на Сайте является выражением письменного согласия со всеми условиями Соглашения и подтверждением факта заключения договора на оказание услуг между Компанией и Пользователем на условиях Соглашения.
      </p> <br />

      <p className="docs-page__text">
        7.2. Настоящее Соглашение, порядок его заключения и исполнения, а также вопросы, не урегулированные настоящим Соглашением, регулируются действующим законодательством Российской Федерации.
      </p> <br />

      <p className="docs-page__text">
        7.3. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту нахождения Правообладателя в соответствии с действующим процессуальным правом Российской Федерации.
      </p> <br />

      <p className="docs-page__text">
        7.4. Настоящее Соглашение может быть изменено или прекращено Правообладателем в одностороннем порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации, в связи с этим.
      </p> <br />

      <p className="docs-page__text">
        7.5. Редакция Соглашения. Действующая редакция настоящего Соглашения размещена на Сайте Правообладателя и доступна в сети Интернет по адресу <a href="https://drsvat.com/"> www.drsvat.com </a>
      </p> <br />

      <p className="docs-page__text">
        7.6. Реквизиты Правообладателя:
      </p> <br />

      <p className="docs-page__text">
        Полное наименование: Общество с ограниченной ответственностью "ДОКТОР СВАТ"
      </p>

      <p className="docs-page__text">
        ИНН/КПП 3662287375/366201001
      </p>

      <p className="docs-page__text">
        Банковские реквизиты:
      </p>

      <p className="docs-page__text">
        Расчетный счет 40702810008500015347
      </p>

      <p className="docs-page__text">
        Банк ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ" БИК 044525999
      </p>

      <p className="docs-page__text">
        Корсчет 30101810845250000999
      </p>

      <p className="docs-page__text">
        Адрес электронной почты: info@drsvat.ru
      </p>

    </div>
    <Footer />
  </div>
);
